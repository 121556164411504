import React from "react";
import Nav from '../components/Nav';
import SEO from '../components/SEO';
import { Link } from "gatsby";

export default function NotFound() {
	return (
		<div>
			<SEO 
				title="unDraw Creatives"
				description="A blog about open-source design and creativity."
			/>
			<Nav />
			<div className='page container' style={{textAlign: "center"}}>
				<h1 className='header__title' style={{marginBottom: 8}}>Page not found</h1>
				<p>The page you are looking for must have been relocated or removed.</p>
			</div>
			<div className='container' style={{marginTop: 32, textAlign: "center"}}>
				<img alt='Empty swing for 404 page' src='./undraw_creatives_not_found.gif' className='notFound__img' />
				<div><Link to="/"><span className="notFound__link">Return to unDraw Creatives</span></Link></div>
			</div>
		</div>
	)
}


